.placeholder-block {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    color: #FFF;
    background-color: #007BFF;
    font-size: 24px;
    margin: 20px 0;
  }
  .placeholder-block > div {
    display: block;
    text-align: left;
    max-width: 600px;
    color: #FFF;
    background-color: #007BFF;
    font-size: 14px;
    margin: 40px;
  }